import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateAuthSessionResponse, CreateElementTokenResponse } from 'api/MethodApi';
import { authSessionThunk, elementTokenThunk } from 'thunks';

export interface MethodAuthData {
  isLoading: boolean;
  entityId: string;
  elementToken?: string;
  cxnId: string;
}

const initialState: MethodAuthData = {
  isLoading: false,
  entityId: '',
  cxnId: '',
};

const methodAuth = createSlice({
  name: 'methodAuth',
  initialState,
  reducers: {
    setCxnId: (state: MethodAuthData, { payload }: PayloadAction<{ cxnId: string }>) => {
      state.cxnId = payload.cxnId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(elementTokenThunk.pending, (state) => {
      state.isLoading = true;
      state.entityId = '';
      state.elementToken = undefined;
    });
    builder.addCase(authSessionThunk.pending, (state) => {
      state.isLoading = true;
      state.entityId = '';
      state.elementToken = undefined;
    });
    builder.addCase(elementTokenThunk.fulfilled, (state, { payload }: PayloadAction<CreateElementTokenResponse>) => {
      state.entityId = payload.entityId;
      state.elementToken = payload.elementToken;
      state.isLoading = false;
    });
    builder.addCase(authSessionThunk.fulfilled, (state, { payload }: PayloadAction<CreateAuthSessionResponse>) => {
      state.entityId = payload.entityId;
      state.isLoading = false;
    });
  },
});

export const { setCxnId } = methodAuth.actions;

export default methodAuth.reducer;

import React from 'react';
import { useSelector } from 'react-redux';

import { getCardData } from 'selectors/getCardData';
import { ReactComponent as CardAppliedIcon } from 'images/card-applied.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { formatNumber } from 'utils/formatNumber';
import useLayoutTheme from 'hooks/useLayoutTheme';
import clsx from 'clsx';
import { FlowComponentType } from 'routes/FlowRouter';
import ReferralProgram from 'components/CardFlow/ReferralProgram/ReferralProgram';

import styles from './CardApplied.module.scss';

const CardApplied = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { themeStyles, theme } = useLayoutTheme();

  const { waitListPosition } = useSelector(getCardData);

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.title}>
          You're number #
          {waitListPosition ? <span className={styles.bold}>{formatNumber(waitListPosition)}</span> : <></>} on the
          Waitlist!
        </div>
        <div className={clsx(styles.description, themeStyles.text)}>
          We’ll keep you updated as we make progress towards launch.
        </div>
        <div className={clsx(styles.iconContainer, styles[`icon__${theme}`])}>
          <CardAppliedIcon />
        </div>
        <ReferralProgram />
        <Button className={styles.button} type={ButtonType.Inverted} onClick={handleNext}>
          Not Now
        </Button>
      </div>
    </div>
  );
};

export default CardApplied;
